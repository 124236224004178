import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../common/header";

function Index() {
  return (
    <div id="wrapper">
      <Header />
      <section className="page-title st3">
        <div className="overlay" />

        <div className="swiper-container slider-main">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slider-st3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box-slider">
                        <div className="content-box">
                          <h1 className="title">$ASTRD Airdrop</h1>
                          <p className="sub-title">
                            Claim your $ASTRD Token worth $107
                          </p>
                          <div className="wrap-btn">
                            <a
                              href="project-list.html"
                              className="tf-button style2"
                            >
                              Claim Now 
                            </a>
                          </div>
                          <div className="flat-box">
                            <div className="box-couter">
                              <p>Potential project</p>
                              <div className="number-content">
                                <h6 className="count-number2">700+</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Market cap</p>
                              <div className="number-content">
                                <h6 className="count-number2">$570.24 M</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Investors</p>
                              <div className="number-content">
                                <h6 className="count-number2">6.297</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="img_main"
                            src="assets/images/common/img_slider3.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  );
}
export default Index;
