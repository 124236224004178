import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header id="header_main" className="header">
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <a href="#">
              <img src="logo.png" alt="" />
            </a>
          </div>
          <nav id="main-nav" className="main-nav">
            <ul id="menu-primary-menu" className="menu">
              <li className="menu-item menu-item-has-children current-menu-item">
                <a href="#">Home</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="#">Home 1</a>
                  </li>
                  <li className="menu-item current-item">
                    <a href="home-02.html">Home 2</a>
                  </li>
                  <li className="menu-item">
                    <a href="home-03.html">Home 3</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Project</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="project-grid.html">Project 01</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-grid-2.html">Project 02</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-grid-3.html">Project 03</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-grid-4.html">Project 04</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-grid-5.html">Project 05</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-list.html">Project List</a>
                  </li>
                  <li className="menu-item">
                    <a href="project-details.html">Project Details</a>
                  </li>
                  <li className="menu-item">
                    <a href="submit-project.html">Submit Project</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Page</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="token.html">Token</a>
                  </li>
                  <li className="menu-item">
                    <a href="connect-wallet.html">Connect Wallet</a>
                  </li>
                  <li className="menu-item">
                    <a href="team-details.html">Team Details</a>
                  </li>
                  <li className="menu-item">
                    <a href="submit-IGO-on-chain.html">Submit IGO on chain</a>
                  </li>
                  <li className="menu-item">
                    <a href="faq.html">FAQs</a>
                  </li>
                  <li className="menu-item">
                    <a href="login.html">Login</a>
                  </li>
                  <li className="menu-item">
                    <a href="register.html">Register</a>
                  </li>
                  <li className="menu-item">
                    <a href="forget-password.html">Forget Password</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item">
                <a href="roadmap.html">Roadmap</a>
              </li>
              <li className="menu-item menu-item-has-children">
                <a href="#">Blog</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="blog-grid.html">Blog Grid</a>
                  </li>
                  <li className="menu-item">
                    <a href="blog-list.html">Blog List</a>
                  </li>
                  <li className="menu-item">
                    <a href="blog-details.html">Blog Detail</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item">
                <a href="contact.html">Contact</a>
              </li>
            </ul>
          </nav>
          {/* /#main-nav */}
          <a href="#" className="tf-button style1">
            {" "}
            Connect{" "}
          </a>
          <div className="mobile-button">
            <span />
          </div>
          {/* /.mobile-button */}
        </div>
      </div>
    </header>
  );
}
export default Header;